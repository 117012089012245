<template>
  <div>
    <van-nav-bar left-arrow
                 title="审批操作"
                 fixed
                 @click-left="back" />

    <div class="approve-container">
      <van-form ref="form">
        <van-cell title="当前节点"
                  :value="waitforInfo.name" />

        <van-cell title="下一节点"
                  v-if="parallel"
                  :value="nextNodeName" />

        <van-cell title="下一节点"
                  v-else
                  is-link
                  :value="nextNodeData.name"
                  @click="nextNodeDialog = true" />

        <van-cell title="处理人"
                  v-if="!parallel"
                  v-show="dealingValue"
                  is-link
                  :value="dealingValue.fullName"
                  @click="dealingDialog = true" />

        <van-cell v-show="!nextNodeEnd"
                  :title="item.name + '  处理人'"
                  v-for="(item, index) in nextNodeData.item"
                  :key="index"
                  v-else
                  :is-link="!result.find(o => o.id === item.id)"
                  :value="
            result
              .filter(o => o.id === item.id)
              .map(o => o.fullName)
              .join('、')
          "
                  @click="
            choseDealingPeople(
              item,
              result.find(o => o.id === item.id)
            )
          " />

        <van-cell title="会签意向"
                  v-show="waitforInfo.isCountersign"
                  is-link
                  :value="agree | agreeFil"
                  @click="agreeDialog = true" />

        <van-cell title="紧急程度"
                  :value="waitforInfo.priority | priorityFil" />

        <van-field v-model="opinion"
                   rows="2"
                   autosize
                   label="审批意见"
                   placeholder="请输入"
                   type="textarea"
                   maxlength="200"
                   show-word-limit
                   :required="isBack"
                   :rules="[{ required: isBack, message: '请填写审批意见' }]" />
        <div style="color: #0f6ecd;line-height:40px;padding-left:106px;font-size:13px;background:#fff;"
             @click="saveAfterAddOpioion">设置为常用意见</div>
      </van-form>
      <div style="color: #0f6ecd;line-height:40px;padding-left:16px;font-size:14px;">常用意见</div>
      <div style="display:flex;flex-wrap:wrap;padding:0px 15px; 10px"
           class="vanTags">
        <van-tag plain
                 type="primary"
                 size="large"
                 v-for="(item,index) in commonOpinions"
                 :key="index"
                 @click="opinion=item.opinion"
                 style="margin-right: 10px;margin-bottom:10px;">
          {{item.opinion}}
        </van-tag>
      </div>

    </div>

    <div style="width:100%;position:fixed;bottom:0;height:50px;display:flex;">
      <van-button size="small"
                  type="info"
                  style="flex:1;height:50px;font-size:16px;"
                  @click="handleSubmit()">确定</van-button>
      <van-button size="small"
                  style="flex:1;height:50px;font-size:16px;"
                  @click="back">取消</van-button>
    </div>

    <van-dialog v-model="nextNodeDialog"
                title="选择下一节点"
                show-cancel-button
                @confirm="choseNextNode">
      <van-radio-group v-model="nextNodeData">
        <van-cell-group>
          <van-cell :title="item.name"
                    clickable
                    v-for="(item, index) in nextNodeSelectData"
                    :key="index"
                    @click="nextNodeData = item">
            <van-radio slot="right-icon"
                       :name="item" />
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </van-dialog>

    <van-dialog v-model="dealingDialog"
                title="选择处理人"
                show-cancel-button>
      <van-radio-group v-model="dealingValue">
        <van-cell-group>
          <van-cell :title="item.fullName"
                    clickable
                    v-for="(item, index) in dealingSelectData"
                    :key="index"
                    @click="dealingValue = item">
            <van-radio slot="right-icon"
                       :name="item" />
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </van-dialog>

    <van-dialog v-model="dealingParallelDialog"
                title="选择处理人"
                show-cancel-button>
      <van-checkbox-group v-model="result">
        <van-cell-group>
          <van-cell :title="item.fullName"
                    clickable
                    v-for="(item, index) in dealingSelectData"
                    :key="index"
                    @click="toggle(index)">
            <van-checkbox slot="right-icon"
                          ref="checkboxes"
                          :name="item" />
          </van-cell>
        </van-cell-group>
      </van-checkbox-group>
    </van-dialog>

    <van-dialog v-model="agreeDialog"
                title="选择会签意见"
                show-cancel-button>
      <van-radio-group v-model="agree">
        <van-cell-group>
          <van-cell title="同意"
                    clickable
                    @click="agree = '1'">
            <van-radio slot="right-icon"
                       name="1" />
          </van-cell>
          <van-cell title="不同意"
                    clickable
                    @click="agree = '0'">
            <van-radio slot="right-icon"
                       name="0" />
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </van-dialog>
  </div>
</template>

<script>
import { Toast } from 'vant';
export default {
  name: 'approvalPanel',
  data () {
    return {
      detailPageName: '',
      agree: '1',
      result: [],
      nextNodeName: '',
      transactData: {},
      waitforInfo: {},
      opinion: '',
      nextNodeDialog: false,
      nextNodeData: {},
      dealingDialog: false,
      dealingParallelDialog: false,
      agreeDialog: false,
      dealingValue: '',
      radio: '',
      nextNodeSelectData: [],
      dealingSelectData: [],
      parallel: false,
      detail: null,
      nextNodeEnd: false,
      isBack: null,
      toName: '',
      commonOpinions: []
    };
  },
  created () {
    console.log('transactData');
    console.log(this.$route.query.transactData);
    console.log('detail');
    console.log(this.$route.query.waitforInfo);
    this.detail = this.$route.query.detail;
    this.detailPageName = this.$route.query.detailPageName || '';

    console.log(this.$route.query, 'aaaaaaaaaaaaaaaaaaa')
  },
  mounted () {
    this.transactData = this.$route.query.transactData;
    this.waitforInfo = this.$route.query.waitforInfo;
    console.log(this.waitforInfo.name, 6666666666666666666666666666666);
    this.isBack = this.$route.query.isBack;
    if (this.$route.query.isBack) {
      this.nextNodeSelectData = this.transactData.backActivityList;
      this.dealingSelectData = this.nextNodeSelectData[0].item;
      this.nextNodeData = this.nextNodeSelectData[0];
      this.dealingValue = this.dealingSelectData[0];
    } else {
      if (this.transactData.activityList.length > 0) {
        //非并行
        this.nextNodeSelectData = this.transactData.activityList;
        if (this.nextNodeSelectData[0].item) {
          this.dealingSelectData = this.nextNodeSelectData[0].item.selectItems;
          console.log('this.dealingSelectData', this.dealingSelectData);

          this.dealingValue = this.dealingSelectData[0];
        }
        console.log('nextNodeData1', this.nextNodeSelectData[0]);
        this.nextNodeData = this.nextNodeSelectData[0];
      } else {
        //并行网关
        this.parallel = true;
        this.nextNodeData = this.transactData.parallelActivityList[0];
        this.nextNodeName = this.nextNodeData.item.map(o => o.name).join('、');
        console.log('this.nextNodeData', this.nextNodeData.item);
        if (this.nextNodeData.item[0].id == 'end') {
          this.nextNodeEnd = true;
          return null;
        } else {
          this.nextNodeEnd = false;
        }
        this.nextNodeData.item.forEach(items => {
          //并行某一节点的处理人为一个时，默认选中
          console.log('selectItems', items);
          if (items.item.selectItems.length === 1) {
            this.result.push({
              fullName: items.item.selectItems[0].fullName,
              id: items.id,
              name: items.name,
              userName: items.item.selectItems[0].userName,
              notLink: true
            });
          }
        });
      }
    }
    if (this.transactData) {
      this.commonOpinions = this.transactData.commonOpinions;
    }
  },
  methods: {
    saveAfterAddOpioion () {
      if (this.opinion == '') {
        this.$notify({ type: 'danger', message: '未填写审批意见' });
        return null;
      }
      var param = {}
      param.opinion = this.opinion;
      param.userName = sessionStorage.getItem('userName')
      this.$myHttp({
        method: 'POST',
        url: '/microarch/activiti/saveCommonOpinion',
        data: param
      }).then(res => {
        Toast.success('设置成功');
        this.commonOpinions.unshift({ 'opinion': this.opinion })
      })
    },
    toggle (index) {
      this.$refs.checkboxes[index].toggle();
    },
    choseDealingPeople (item, data) {
      if (data && data.notLink) return;
      this.dealingParallelDialog = true;
      item.item.selectItems.forEach(items => {
        items.id = item.id;
        items.name = item.name;
      });
      this.dealingSelectData = item.item.selectItems;
    },
    choseNextNode () {
      this.nextNodeDialog = true;
      if (!this.nextNodeData.item.selectItems) {
        this.dealingSelectData = this.nextNodeData.item;
      } else {
        this.dealingSelectData = this.nextNodeData.item.selectItems;
      }
      this.dealingValue = this.dealingSelectData[0];
    },
    back () {
      this.$router.go(-1);
    },
    handleSubmit () {
      this.$refs.form
        .validate()
        .then(res => {
          this.confirmProcess();
        })
        .catch(err => { });
    },
    confirmProcess () {
      // 强制执行签收动作
      const params = {
        businessKey: this.$route.query.detail.businessKey,
        processInstanceId: this.$route.query.detail.pid,
        processInstanceName: this.$route.query.waitforInfo.businessInfo,
        taskId: this.$route.query.detail.id,
        taskName: this.$route.query.detail.name,
        priority: this.$route.query.detail.priority,
        goId: '',
        opinion: this.opinion,
        email: '1',
        userName: sessionStorage.getItem('userName'),
        isStartTask: false,
        variables: {},
        nextId: this.nextNodeData.id,
        optionType: this.$route.query.isBack ? 'back' : 'submit',
        optionText: this.$route.query.isBack ? '退回' : '提交审批',
        candidateUserIds: {},
        autoNodes: [],
        encyclicUserIds: [''],
        businessUrl: ''
      };
      if (this.parallel) {
        this.nextNodeData.item.forEach(items => {
          params.candidateUserIds[items.id] = this.result.filter(o => o.id === items.id).map(o => o.userName);
        });
      } else {
        params.candidateUserIds = {
          [this.nextNodeData.id]: [this.dealingValue.userName]
        };
      }

      //会签节点，提交同意/不同意参数
      if (this.waitforInfo.isCountersign) {
        params.agree = this.agree === '1';
        params.opinion = (this.agree === '1' ? '同意。' : '不同意。') + this.opinion;
        params.countersign = true;
      }
      // 结束节点 无审批人
      if (params.nextId === 'end' || this.nextNodeEnd) params.candidateUserIds = {};

      if (
        (params.nextId !== 'end' || !this.nextNodeEnd) &&
        params.candidateUserIds &&
        Object.values(params.candidateUserIds).find(item => item.length === 0)
      ) {
        this.$toast.fail('请选择处理人！');
        return;
      }
      this.$myHttp({
        method: 'POST',
        url:
          '/microarch/activiti/claim?taskId=' +
          this.$route.query.detail.id +
          '&userName=' +
          sessionStorage.getItem('userName'),
        data: params
      }).then(res => {
        this.$myHttp({
          method: 'POST',
          url: '/microarch/activiti/send',
          data: params
        }).then(res => {
          //审批成功，跳转
          // 项目组修改
          // this.$router.go(-2);
          var option = 'submitProcess'
          if (this.isBack) {
            option = 'return';
          }
          if (params.nextId == 'end' || res.data.body.isEnded) {
            option = 'endProcess'
          }
          console.log('option');
          console.log(option);
          this.processCallback(option, this.$route.query.type)
          // this.EhrUtils.updateFormState(this, `/hrtbm/${this.detail.updateUrl}/updateFormState`, this.detail.businessKey, this.TbmCons.FormState.INAUDIT).then(res => {
          //   if (res.data.entity) {
          //     this.goWorkFlowPage();
          //   }
          // })
        });
      });
    },
    async processCallback (optionType, taskType) {
      if (optionType == this.TbmCons.OptionType.RETURN) {// 不同意/驳回
        this.EhrUtils.updateFormState(this, `/hrtbm/${this.detail.updateUrl}/updateFormState`, this.detail.businessKey, this.TbmCons.FormState.INAUDIT).then(res => {
          if (res.data.entity) {
            this.goWorkFlowPage();
          }
        });
      }
      if (optionType == this.TbmCons.OptionType.REPEL_PROCESS) {// 撤销审批
        this.EhrUtils.updateFormState(this, `/hrtbm/${this.detail.updateUrl}/updateFormState`, this.detail.businessKey, this.TbmCons.FormState.TOVOID).then(res => {
          if (res.data.entity) {
            this.goWorkFlowPage();
          }
        });
      }
      if (optionType == this.TbmCons.OptionType.FORCE_END_PROCESS) {// 强制结束
        this.EhrUtils.updateFormState(this, `/hrtbm/${this.detail.updateUrl}/updateFormState`, this.detail.businessKey, this.TbmCons.FormState.TOVOID).then(res => {
          if (res.data.entity) {
            this.goWorkFlowPage();
          }
        });
      }
      if (optionType == this.TbmCons.OptionType.END_PROCESS) { // 完成审批
        if (this.$route.query.applyChangeNo) {
          this.EhrUtils.updateFormState(this, `/hrtbm/${this.detail.updateUrl}/updateFormState`, this.$route.query.applyChangeNo, this.TbmCons.FormState.TOVOID);
        }
        this.EhrUtils.updateFormState(this, `/hrtbm/${this.detail.updateUrl}/updateFormState`, this.detail.businessKey, this.TbmCons.FormState.AUDITED).then(res => {
          if (res.data.entity) {
            this.goWorkFlowPage();
          }
        });
      }
      // if (taskType == this.TbmCons.TaskType.TRANSLATED) {// 已办
      //   this.$router.replace({
      //     name: 'workFlow/workFlowList',
      //     type: 'transated'
      //   });
      // } else if (taskType == this.TbmCons.TaskType.WAIT_FOR) {// 待办
      //   this.$router.replace({
      //     name: 'workFlow/workFlowList',
      //     type: 'waitfor'
      //   });
      // } else if (taskType == this.TbmCons.TaskType.TO_READ) {// 待阅
      //   this.$router.replace({
      //     name: 'workFlow/workFlowList',
      //     type: 'read'
      //   });
      // } else if (taskType == this.TbmCons.TaskType.READ) {// 已阅
      //   this.$router.replace({
      //     name: 'workFlow/workFlowList',
      //     type: 'readed'
      //   });
      // }
      if (optionType == this.TbmCons.OptionType.SUBMIT_PROCESS) { // 提交审批
        this.EhrUtils.updateFormState(this, `/hrtbm/${this.detail.updateUrl}/updateFormState`, this.detail.businessKey, this.TbmCons.FormState.INAUDIT).then(res => {
          if (res.data.entity) {
            this.goWorkFlowPage();
          }
        });
      }
    },

    goWorkFlowPage () {
      this.loading = Toast.loading({
        message: '同步中,请等待...',
        forbidClick: true,
        duration: 0
      });
      setTimeout(() => {
        Toast.clear();
        this.$router.replace({
          name: 'Home',
        });
      }, 5000);
    }
  },

  beforeRouteLeave (to, from, next) {
    if (this.detailPageName && to.name == this.detailPageName && !this.$route.params.taskType) {
      this.$myHttp({
        method: 'GET',
        url: '/microarch/activiti/instance/delete/' + this.$route.query.detail.pid,
        data: {}
      }).then(res => {
        if (res.status == 200) {
          next();
        }
      });
    } else {
      sessionStorage.removeItem('formData');
      next();
    }
  },

  filters: {
    priorityFil (val) {
      const colorMap = {
        50: ['green', '一般'],
        100: ['orange', '紧急'],
        150: ['red', '特急']
      };

      let color = colorMap[val] || ['green', '一般'];

      return color[1];
    },
    agreeFil (val) {
      return val === '1' ? '同意' : '不同意';
    }
  }
};
</script>

<style scoped lang="less">
.approve-container {
  margin-top: 40px;
  margin-bottom: 20px;
}
.van-radio {
  margin-left: 5px;
}
</style>
<style>
.vanTags > span {
  max-width: 95%;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>

