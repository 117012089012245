<template>
    <div>
        <van-nav-bar title="通讯录"
                     fixed
                     left-arrow
                     @click-left="back">
            <van-icon name="search" slot="right" @click="showSearch"/>
        </van-nav-bar>

        <div class="content">
            <van-search v-if="isShowSearch"
                        placeholder="请输入姓名"
                        @search="onSearch"
                        v-model="queryCondition"
                        shape="round"
                        show-action
                        @cancel="onCancel"/>

            <div v-show="!isShowSearch">
                <div class="breadcrumb">
                    {{menuBreadcrumb}}
                </div>
                <div class="content-list">
                    <div v-if="deptList.length > 0" v-for="(value, key) in deptList">
                        <van-cell :title="value.text" icon="friends-o" @click="getDeptList(value.id,value.text)"/>
                    </div>
                    <div v-if="userList.length > 0" v-for="(value, key) in userList">
                        <van-cell :title="value.cname" :value="value.mobile" icon="user-o"/>
                    </div>
                </div>
            </div>

            <div v-if="isShowSearch">
                <div v-if="searchUserList.length > 0" v-for="(value, key) in searchUserList">
                    <van-cell :title="value.cname" :value="value.mobile" :label="value.deptName" icon="user-o"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'addressBookList',
        data() {
            return {
                orgName: '',
                orgId: '',
                deptName: '',
                deptId: '',
                previousDeptId: [],
                deptNameList: [],
                deptList: [],
                userList: [],
                searchUserList: [],
                isBack: false,
                menuBreadcrumb: '',
                isShowSearch: false,
                queryCondition: '',
            };
        },
        created() {
            this.getOrgInfo();
        },
        methods: {
            getOrgInfo() {
                if (sessionStorage.getItem("sys_org")) {
                    let orgInfo = JSON.parse(sessionStorage.getItem("sys_org"));
                    console.log("orgInfo", orgInfo);
                    this.orgName = orgInfo.orgName;
                    this.orgId = orgInfo.orgId;
                    this.getDeptList();
                }
            },
            getDeptList(deptId, deptName) {
                if (!this.isBack && deptId) {
                    this.previousDeptId.push(this.deptId);
                    this.deptNameList.push(deptName);
                }
                this.deptId = deptId || "";

                this.$myHttp({
                    method: 'post',
                    url: 'microarch/sys/sysdept/deptManagerTree',
                    data: {
                        entity: {
                            "lang": "zh_CN",
                            "orgId": this.orgId,
                            "pid": deptId || ""
                        }
                    }
                }).then(res => {
                    if (res.data.ext) {
                        let data = JSON.parse(res.data.ext);
                        this.deptList = data;
                        this.getUserList(deptId);
                        this.getBreadcrumb();
                        this.isBack = false;
                    }
                });
            },
            getUserList(deptId) {
                this.userList = [];
                if (deptId) {
                    this.$myHttp({
                        method: 'post',
                        url: 'microarch/sys/syscommon/findUsersByDept',
                        data: {
                            entity: {
                                "lang": "zh_CN",
                                "deptId": deptId,
                            }
                        }
                    }).then(res => {
                        if (res.data.rows.length > 0) {
                            console.log('userInfo', res.data.rows);
                            this.userList = res.data.rows;
                        }
                        if (this.deptList.length ===0 && res.data.rows.length === 0) {
                            this.$toast({type: 'text', message: '暂无数据'});
                        }
                    });
                }
            },
            back() {
                if (this.previousDeptId.length > 0) {
                    this.isBack = true;
                    this.deptList = this.userList = [];
                    let preDeptId = this.previousDeptId.pop();
                    this.deptNameList.pop();
                    this.getDeptList(preDeptId)
                }
            },
            getBreadcrumb() {
                this.menuBreadcrumb = this.orgName;
                if (this.deptNameList.length > 0) {
                    this.deptNameList.forEach(value => {
                        this.menuBreadcrumb += "/" + value
                    })
                }
            },
            showSearch() {
                this.isShowSearch = true;
                this.searchUserList = [];
            },
            onSearch() {
                console.log('查询条件', this.queryCondition)
                this.$myHttp({
                    method: 'post',
                    url: 'microarch/sys/syscommon/findUsersByOrg',
                    data: {
                        "entity": {
                            "companyId": this.orgId,
                            "lang": "zh_CN",
                        },
                        "where": [
                            {
                                "field": "cname",
                                "value": this.queryCondition
                            }
                        ]
                    }
                }).then(res => {
                    this.deptList = this.userList = [];
                    console.log('条件查询', res)
                    if (res.data.rows) {
                        let data = res.data.rows;
                        this.searchUserList = data;
                    }
                    if (res.data.rows.length === 0) {
                        this.$toast({type: 'text', message: '暂无数据'});
                    }
                });
            },
            onCancel() {
                this.isShowSearch = false;
                this.getDeptList();
            }
        }
    }
    ;
</script>

<style scoped>
    .content {
        margin-top: 50px;
    }

    .breadcrumb {
        text-align: center;
        font-size: 14px;
        line-height: 1;
        font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, SimSun, sans-serif;
    }

    .content-list {
        margin-top: 5px;
    }
</style>
